
import Vue from "vue";

import ActivitiesMixin from "@/components/ActivitiesView/ActivitiesMixin.vue";

interface IPolicyActivitiesData {
  error: string;
  message: string;
}

export default Vue.extend({
  name: "propertyActivitiesView",
  mixins: [ActivitiesMixin],

  data(): IPolicyActivitiesData {
    return {
      error: "",
      message: ""
    };
  },
  computed: {
    queryOverride(): any {
      return {
        name__in: `
          DELETE_BULK_POLICY_CHANGE_REQUEST_SUCCESS,
          DELETE_BULK_POLICY_CHANGE_REQUEST_FAILURE,
          UPDATE_POLICY_CHANGE_REQUEST_SUCCESS,
          UPDATE_POLICY_CHANGE_REQUEST_FAILURE,
          ADD_POLICY_CHANGE_REQUEST_SUCCESS,
          ADD_POLICY_CHANGE_REQUEST_FAILURE,
          ADD_POLICY_CHANGE_REQUEST_SUCCESS,
          ADD_POLICY_CHANGE_REQUEST_FAILURE,  
          LASER_FICHE_FILE_UPLOAD_FAILURE,
          LASER_FICHE_FILE_UPLOAD_SUCCESS`.replace(/\s/g, ""),
        activityType: "PolicyChangeRequestActivity"
      };
    }
  }
});
